import React, { useCallback, useEffect, useState,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Drawer, theme, Spin,Dropdown,Menu } from "antd";
import { get, isEmpty } from "lodash";
import { Logo, Hamburger, Close,ProfileLogo } from "../../icons";
import moment from "moment";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import OtherNewsDefault from "../../../images/other_news_default.png";
import ThemesImage from "../../../images/themes_default.png";
import IcSearch from "../../../images/ic-search-dark.svg";
import ReactModal from "react-modal";
import { useAuth } from "../../../utils/AuthContext";



const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
)
let cancelToken;
let cancelTimeout;

const Header = ({
  props,
  ltWeeklyItemData,
  onTabChange,
  activeTab,
  SavedLTWebsiteAnalytics,
  SearchThemesAndOtherNews,
  CheckUserAuthenticityAndSendToken,
}) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [open, setOpen] = useState(false);
  const [subTab, setSubTab] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [news, setOtherNews] = useState([]);
  const [themes, setThemes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [isSearchTextEmpty, setIsSearchTextEmpty] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [searchResultTotalCount, setSearchResultTotalCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [mailModalIsOpen,setMailModalIsOpen] =useState(false)
  // const [successModalIsOpen,setSuccessModalIsOpen] =useState(false)
const [themeLink,setThemeLink]= useState("")
const [userEmail,setUserEmail]= useState("")
const [errorMessage,setErrorMessage]=useState("")
const [emailloader,setEmailLoader]=useState(false)
const [isAcknwoledgmentModalOpen,setIsAcknwoledgmentModalOpen] = useState(false)
const [isLinkModalOpen,setLinkModalOpen]=useState(false)
  const [isApiCalled, setIsApiCalled] = useState(false)
  const [isButtonDisabled,setIsButtonDisabled]=useState(false)
  const { userEmailId, userName,login, logout,token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  const emailInputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);

  };
  // const handleBlur = (e) => {
  //   // if (!e.currentTarget.contains(e.relatedTarget)) {
  //   //   setIsFocused(false);
  //   // }
  //   setTimeout(() => {
  //     // Ensure no clicks inside the dropdown or specific elements before closing
  //     if (!e.relatedTarget?.closest(".test")&& !e.relatedTarget?.closest(".see-all-results")) {
  //       setIsFocused(false);
  //     }
  //   }, 200)
  // };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setLoader(false);
    setOpen(false);
    setVisible(false);
    setSubTab("");
    setSearchText("");
    setIsSearchTextEmpty(true)
    setOtherNews([]);
    setThemes([]);
    setShowNoDataMessage(false);
    onTabChange(activeTab);
  };

  const getThemeTitle = (title) => {
    if (title?.includes("<span")) {
      title = title?.split("<span>")[1];
      title = title?.split("</span>")[0];
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title?.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title?.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          if (title?.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title?.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title?.includes("<a")) {
      if (title?.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title?.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const handledClicked = (actionDescription, val) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    // "June 2, 2022 at 1:30pm EST"
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: get(ltWeeklyItemData, "ItemId", ""),
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
    };
    SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const debounce = (func,delay = 500) => {
    let timer;
    return function (...args) {
      // const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        // timer = null;
        func.apply(this, args);
      }, delay);
    };
  };
  const imageOk = async (image, imageTag) => {
    let oImg = new Image();
    oImg.src = image;
    let status = false;
    oImg.onload = async () => {
      // return true;
      status = true;
      return status;
    };
    oImg.onerror = async () => {
      status = false;
      let image = document.querySelector(imageTag);
      if (image) {
        image.src = OtherNewsDefault;
      }
      return status;
    };
  };

  useEffect(() => {
    let { pathname,search } = location;
    
    if (pathname === "/LTWeekly") {
      // headerAnalytics('Home Page')
    } else if (pathname.includes("/LTWeekly/archives/")) {
      // headerAnalytics('Archive  Page')
    } else if (pathname === "/LTWeekly/about") {
      // headerAnalytics('About')
    }
    
    // const queryParams = new URLSearchParams(search);
    // const email = queryParams.get('email');

    // if (!isEmpty(search) & !localStorage.getItem("t")) {

    //   localStorage.setItem(
    //     "t",
    //     search.replace("?token=", "")?.replace("?t=", "").replace("&email=true","")
    //   );

    //   if(email && !isApiCalled){
    //   callValidateApi()
      
    //   }
    // }
    
  }, [location.pathname]);

  // const callValidateApi=async()=>{
  //   setIsApiCalled(true)
  //   const {value}=await ValidateLink()
  //   if (!value?.Success){
  //     openLinkModal()
  // }
  // }


  const callSearchApi = async (e = "", data = {}) => {
    let searchValue;
    if (isEmpty(e) || e?.target?.value === "") {
      setOtherNews([]);
      setThemes([]);
      setIsSearchTextEmpty(true);
      return;
    }
    if (!isEmpty(e)) {
      setLoader(true);
      searchValue = e.target.value;
      setSearchText(searchValue);
      setIsSearchTextEmpty(searchValue === "");
    }
    let payload = {};

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    if (!isEmpty(e) && e.target?.value !== "") {
      payload = {
        Text: e.target.value,
        token: cancelToken.token,
        Page: 1,
        Count:50,
      };
    } 
    // else {
    //   payload = {
    //     Text: "",
    //     token: cancelToken.token,
    //     Page: 1,
    //     Count: 50,
    //   };
    // }
    clearInterval(cancelTimeout);
    setShowMessage(false);

    const { value } = await SearchThemesAndOtherNews(payload);
    // if (typeof cancelTimeout !== "undefined") {
    // }
    if (value?.Success) {
      const themes = value?.Result?.filter((item) => item.Type === 0);
      const news = value?.Result?.filter((item) => item.Type === 2);
      setSearchResultTotalCount(value?.TotalCount);
      setIsSearchTextEmpty(false);
      setOtherNews(news);
      setThemes(themes);
      if (!isEmpty(searchValue)) {
        cancelTimeout = setInterval(() => {
          setShowNoDataMessage(false);
          setLoader(false);
          clearInterval(cancelTimeout);
        }, 250);
      }
    } else {
      if (!isEmpty(searchValue)) {
        cancelTimeout = setInterval(() => {
          setShowNoDataMessage(
            value?.TotalCount === 0 ||
              value?.Result?.length === 0 ||
              value?.Result === null
          );
          setShowMessage(true);
          setIsSearchTextEmpty(false);
          setLoader(false);
          clearInterval(cancelTimeout);
        }, 250);
      }
      setOtherNews([]);
      setThemes([]);
    }
    setShowMessage(false);
    
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      
      const inputElement = document.getElementById("searchinput");

      if (!event.target.closest(".test") 
     ) {
      if (document.activeElement === inputElement) {
        setIsFocused(true);
      }else{
      setIsFocused(false);
      console.log("herer1")
      if (inputElement.value) {
        inputElement.value = "";
      }
      setSearchText("")
        cancelTimeout = setInterval(() => {
        setThemes([])
        setOtherNews([])
        clearInterval(cancelTimeout);
        }, 200);
        
        setLoader(false)
        
        
      }
    }
      
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [isFocused]);
  console.log(isFocused,"is in focus==>")


  const getHrefUrl = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const linkElement = doc.querySelector("a");
    const hrefValue = linkElement?.getAttribute("href")?.replaceAll("?t=","")
    return hrefValue;
  };

  const redirectToLink = (link) => {
    const updatedLink = link.replaceAll("?t=","")
    window.open(updatedLink, "_blank");
  };

  const getExternalUrl = (externalUrl) => {
    window.open(externalUrl, "_blank");
  };
   const optimizedUserFn = useCallback(debounce(callSearchApi, 800), []);
 

  useEffect(() => {}, [subTab, activeTab,news,themes]);
  const maxitems = 2;

  const handleSeeAllResults = (e) => {
    // e.preventDefault();
    if(!localStorage.getItem("t")){
      setIsFocused(false)
      setSearchText("")
      openMailModal()
    }

    setSearchText("");
    setOtherNews([]);
    setThemes([]);
    if(localStorage.getItem("t")){
      setIsFocused(false)
      setSearchText("")
    window.location.href = `/LTWeekly/search?searchText=${searchText}`;
    
    }
  };
  const closeMailModal = () => {
    setMailModalIsOpen(false)
    setErrorMessage("")
    setThemeLink("")
    setUserEmail("")
    setIsButtonDisabled(false)
    // setIsFocused(false)
    //  setSearchText("")
    //  setIsSearchTextEmpty(true)
   };
   const openMailModal = (getLink) => {
     setThemeLink(getLink)
    //  setSearchText("")
    //  setIsSearchTextEmpty(true)
     setMailModalIsOpen(true)  
   };
  //  const closeSuccessModal = () => {
  //   setUserEmail("")
  //   setSuccessModalIsOpen(false)
  //  };
  //  const openSuccessModal = () => {
  //    setSuccessModalIsOpen(true)
  //  };
   const checkUser= async (email,link)=>{
    setIsButtonDisabled(true)
     if(email===""){
       setErrorMessage('Please enter email')
       setIsButtonDisabled(false)
       return
     }
     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     if (!regEmail.test(email)) {
       setErrorMessage('Invalid Email Address')
       setIsButtonDisabled(false)
       return
     }
     let obj ={
       email:email,
       link:link?link:`${process.env.REACT_APP_SITE_URL}/LTWeekly`,
     }
     setEmailLoader(true)
     const {value}=await CheckUserAuthenticityAndSendToken(obj)
     if (value?.Success){
      setEmailLoader(false)
      if(value.Message==="User is present"){
        let token = value.Result.replaceAll("?t=","")
        // openSuccessModal()
      login(token);
      setEmailLoader(false)
        setMailModalIsOpen(false)
        }
        else{
          opneAcknwoledgmentModal()
        }
     }else{
      setEmailLoader(false)
     window.location.href = 'https://liontreeuat.wpenginepowered.com/ltweekly/?inboundpath=themeweb'
       setUserEmail("")
       setMailModalIsOpen(false)
     }
     setMailModalIsOpen(false)
     setErrorMessage("")
     setIsButtonDisabled(false)
     setEmailLoader(false)
   }
   const handleChangeEmail =(email) =>{  
     const userEmail=email
     setUserEmail(userEmail)
     setErrorMessage("")
   }
   const opneAcknwoledgmentModal =()=>{
    setIsAcknwoledgmentModalOpen(true)
  }
  const closeAcknowledgmentModal=()=>{
    setIsAcknwoledgmentModalOpen(false)
  }
  const openLinkModal = () => {
    setLinkModalOpen(true)
  };
  const closeLinkModal = () => {
    localStorage.removeItem("t")
    setLinkModalOpen(false)
    navigate('/LTWeekly')
   };
  //  const handleLogout=()=>{
  //   setUserEmailId("")
  //   setUserName("")
  //   localStorage.removeItem("t")
  //  }
  const searchOpen=()=>{
    setOpen(true)
   setSubTab("search");
  }
 
  document.addEventListener("DOMContentLoaded", () => {
    const divElement = document.querySelector(".sidebarSearch");
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile && divElement) {
        const reduceHeight = 67; 
        const setDivHeight = () => {
            const viewportHeight = window.innerHeight;
            divElement.style.height = `${viewportHeight - reduceHeight}px`;
        };
        setDivHeight();
        window.addEventListener("resize", setDivHeight);
    }
});
const handleAfterOpenModal = () => {
  if (emailInputRef.current) {
    emailInputRef.current.focus(); 
  }
};

  return (
    <>
      <header className="top__navigation top__navigation--fixed">
        <div className="top__navigation--container">
          <div className="top__navigation--row">
            <div className="top__navigation--left">
              <div className="top__navigation--hamburger">
                <button className="btn btn__hamburger" onClick={showDrawer}>
                  <Hamburger />
                </button>
              </div>
              <div className="top__navigation--logo">
                <Link
                  to="/LTWeekly"
                  // onClick={() => headerAnalytics("Home Logo")}
                  className="brand"
                >
                  <Logo />
                </Link>
              </div>
              <ul className="top__navigation--nav">
                <li>
                  <button
                    className={
                      activeTab === "home" && subTab !== "search"
                        ? "nav__link active"
                        : "nav__link"
                    }
                    onClick={() => {
                      setSubTab("");
                      onTabChange("home");
                      // headerAnalytics("Home Page")
                    }}
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "archives" && subTab !== "search"
                        ? "nav__link active"
                        : "nav__link"
                    }
                    onClick={() => {
                      setSubTab("");
                      // headerAnalytics("Archive Page")
                      onTabChange("archives");
                    }}
                  >
                    LionTree Top Themes
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "news" && subTab !== "search"
                        ? "nav__link active"
                        : "nav__link"
                    }
                    onClick={() => {
                      setSubTab("");
                      onTabChange("news");
                      // headerAnalytics("Home Page")
                    }}
                  >
                    By Sub-Sector
                  </button>
                </li>
                <li className="mobile-search">
                  <button
                    className={
                      subTab === "search" ? "nav__link active" : "nav__link"
                    }
                    onClick={() => {
                      setVisible(false);
                      searchOpen()
                      // headerAnalytics("Home Page")
                    }}
                  >
                    Search
                  </button>
                </li>

                <li>
                  <button
                    className={
                      activeTab === "about" && subTab !== "search"
                        ? "nav__link active"
                        : "nav__link"
                    }
                    onClick={() => {
                      setSubTab("");
                      // headerAnalytics("About Page")
                      onTabChange("about");
                    }}
                  >
                    About
                  </button>
                </li>
              </ul>
            </div>
            <div className="top__navigation--right">
              <div className="d-flex">
                {!location.pathname.includes("/LTWeekly/search") && (
                  <div className="me-3 desktop-search ">
                    <div
                      className={
                        isFocused ? "otherDiv test focused" : "test otherDiv"
                      }
                    >
                      <input
                        id="searchinput"
                        type="search"
                        placeholder="Search..."
                        className="search-header"
                        onFocus={handleFocus}
                        // onBlur={handleBlur}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          optimizedUserFn(e);
                        }}
                      />
                      {searchText == "" &&
                        news.length === 0 &&
                        themes.length === 0 && (
                          <div className="text-center pt-3 SearchWeeklies">
                            <img src={IcSearch} />
                            <p>Search weeklies or key news </p>
                          </div>
                        )}
                      {loader ? (
                        <Spin
                          size="small"
                          className="loader__small"
                          style={{ paddingTop: "36px" }}
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 30,
                                color: "#000",
                                textAlign: "center",
                              }}
                              spin
                            />
                          }
                        />
                      ) : (
                        <div
                          className={(isFocused) ? "" : "hide-search-results"}
                        >
                          <div className="sidebarSearch mt-3 mb-0">
                            <div className="searchItem">
                              { themes && themes.length>0 &&
                                themes.slice(0, maxitems)?.map((t) => {
                                  return (
                                    <div
                                      className="itemList itemList-themes"
                                      // onClick={() => redirectToLink(getHrefUrl(t.ShortDescription))}
                                      key={t.ShortDescription}
                                    >
                                      <div
                                        className="itemImg"
                                        onClick={() =>{
                                          // redirectToLink(
                                          //   getHrefUrl(t.ShortDescription)
                                          // )
                                          // window.open(
                                          //   `${
                                          //     process.env.REACT_APP_SITE_URL +
                                          //     "/LTWeekly/" +
                                          //     moment(
                                          //       new Date(t.ItemName)
                                          //     ).format("MMM-DD-yyyy") +
                                          //     "/" +
                                          //     t.Id
                                          //   }`,
                                          //   "_blank"
                                          // )
                                      
                                          if(!localStorage.getItem("t")){
                                            openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                              new Date(t.ItemName)
                                            ).format("MMMM-DD-yyyy")}/${t.Id}`)
                                            setSearchText("")
                                          }
                                          else{
                                          window.open(
                                            `/LTWeekly/${moment(
                                              new Date(t.ItemName)
                                            ).format("MMMM-DD-yyyy")}/${t.Id}`,
                                            "_blank"
                                          )
                                        }
                                      }}
                                      >
                                        <img
                                          src={
                                            t.Photo?.includes(
                                              "ltuatwestus2storpublic"
                                            )
                                              ? t.Photo
                                              : ThemesImage
                                          }
                                        />
                                      </div>
                                      <div className="itemDetail">
                                        <div className="itemTitle"
                                        //  onClick={() =>{
                                          // redirectToLink(
                                          //   getHrefUrl(t.ShortDescription)
                                          // )
                                          // window.open(
                                          //   `${
                                          //     process.env.REACT_APP_SITE_URL +
                                          //     "/LTWeekly/" +
                                          //     moment(
                                          //       new Date(t.ItemName)
                                          //     ).format("MMM-DD-yyyy") +
                                          //     "/" +
                                          //     t.Id
                                          //   }`,
                                          //   "_blank"
                                          // )
                                        //   if(!localStorage.getItem("t")){
                                        //     openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                        //       new Date(t.ItemName)
                                        //     ).format("MMMM-DD-yyyy")}/${t.Id}`)
                                        //   }
                                        //   else{
                                        //   window.open(
                                        //     `/LTWeekly/${moment(
                                        //       new Date(t.ItemName)
                                        //     ).format("MMMM-DD-yyyy")}/${t.Id}`,
                                        //     "_blank"
                                        //   )
                                        // }
                                      // }}
                                      >
                                          {t.ThemeIndex}.{" "}
                                          <a
                                            // href={`${
                                            //   process.env.REACT_APP_SITE_URL +
                                            //   "/LTWeekly/" +
                                            //   moment(
                                            //     new Date(t.ItemName)
                                            //   ).format("MMM-DD-yyyy") +
                                            //   "/" +
                                            //   t.Id
                                            // }`}
                                            // href={`/LTWeekly/${moment(
                                            //   new Date(t.ItemName)
                                            // ).format("MMMM-DD-yyyy")}/${t.Id}`}
                                            // target="_blank"
                                            // rel="noopener noreferrer"
                                            href={localStorage.getItem("t")?getHrefUrl(t.ShortDescription):""}
                                            onClick={(e)=>{if(!localStorage.getItem('t')){
                                              e.preventDefault()
                                              openMailModal(getHrefUrl(t.ShortDescription))
                                            }}}
                                            className="itemTitle"
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {t.ShortDescription?.includes(
                                              "<a"
                                            ) ? (
                                              getThemeTitle(t.ShortDescription)
                                            ) : (
                                              <>
                                                {t.ShortDescription.replaceAll(
                                                  "<span>",
                                                  ""
                                                ).replaceAll("</span>", "")}
                                              </>
                                            )}
                                          </a>
                                        </div>
                                        <p className="itemPara">
                                          LT Weekly:{" "}
                                          {moment(
                                            t.ItemName,
                                            "MM-DD-YYYY"
                                          ).format("DD MMM, YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              {news && news.length>0 &&
                                news.slice(0, maxitems)?.map((n, i) => {
                                  return (
                                    <div
                                      className="itemList"
                                      // onClick={() => getExternalUrl(n.ExtUrl)}
                                      key={n.ExtUrl}
                                    >
                                      <div
                                        className="itemImg"
                                        onClick={() => getExternalUrl(n.ExtUrl)}
                                      >
                                        <img
                                          style={{
                                            backgroundImage: imageOk(
                                              n.Photo?.replaceAll("&amp;", "&"),
                                              `.image${i}`
                                            )
                                              ? ""
                                              : `url(${OtherNewsDefault})`,
                                            backgroundSize: `cover`,
                                            backgroundPosition: `center`,
                                          }}
                                          className={`image${i}`}
                                          src={
                                            n.Photo &&
                                            n.Photo !== "" &&
                                            imageOk(
                                              n.Photo?.replaceAll("&amp;", "&"),
                                              `.image${i}`
                                            )
                                              ? n.Photo?.replaceAll(
                                                  "&amp;",
                                                  "&"
                                                )
                                              : OtherNewsDefault
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="itemDetail">
                                        <a
                                          href={n.ExtUrl}
                                          className="itemTitle dark-title"
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                          target="_blank"
                                        >
                                          {n.ShortDescription}
                                        </a>
                                        <p className="itemPara purple-title">
                                          {" "}
                                          {/* Other News | {n.Titles?.replaceAll(" |", " | ")} */}
                                          Other News:{" "}
                                          {moment(
                                            n.ItemName,
                                            "MM-DD-YYYY"
                                          ).format("DD MMM, YYYY")}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              {(!loader && searchText !== "" &&
                                !isSearchTextEmpty &&
                                !showNoDataMessage) &&
                                (themes.length > 2 ||
                                  news.length > 2) &&
                                 (
                                  <div
                                    onClick={(e) => {
                                      handleSeeAllResults(e);
                                      // navigate(`/LTWeekly/search?searchText=${searchText}`);
                                    }}
                                    // onMouseDown={handleSeeAllResults}
                                    className="see-all-results"
                                  >
                                    
                                    See all results
                                  </div>
                                )}
                              {!loader && searchText !== "" &&
                                !isSearchTextEmpty &&
                                showNoDataMessage &&
                                showMessage && (
                                  <div className="table__blank">
                                    <div className="table__blank--text loader__small d-inline">
                                      We didn’t find any matches for{" "}
                                      <b className="boldtext">"{searchText}"</b>
                                      . Please try to search for something else.
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!token ?(
                  <>
                <button className="btn btn__purple btn__subscribe me-2"
                onClick={ ()=>
                  openMailModal()
                }>
                    Login
                  </button>
                  
                <a
                  onClick={() =>
                    handledClicked(
                      "Request Subscription",
                      "https://liontreeuat.wpenginepowered.com/ltweekly?inboundpath=themeweb"
                    )
                  }
                  // href="javascript:void(0)"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn__purple btn__subscribe">
                    Request Access
                  </button>
                </a>
                </>
                 ):( 
                <div className="profile-gap">
                <Dropdown
                    trigger={["click"]}
                    placement="topRight"
                    overlayClassName="top__navigation--dropdown"
                    
                    overlay={
                      <Menu>
                        <div className="profile__wrapper">
                          <div className="profile__name">
                            {userEmailId}
                          </div>
                        </div>
                          <Menu.Item key="1">
                            <Link
                              to="/LTWeekly"
                              className="top__navigation--dropdown-link"
                              onClick={logout}
                            >
                              Logout
                            </Link>
                          </Menu.Item>
                     </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link nav__link"
                      onClick={(e) => e.preventDefault()}
                    >
                {userName ? (
            <span className="profileCircle">
              <h1 className="ProfileName">
                {userName?.trim()?.charAt(0)?.toUpperCase()}
              </h1>
            </span>
          ) : (
            <span className="profileCircle">
              <h1 className="ProfileName">
                {userEmailId?.trim()?.charAt(0)?.toUpperCase()}
              </h1>
            </span>
          )}
        </a>
                </Dropdown>
              </div>
                  
            )}
              </div>
              
            </div>
          </div>
        </div>
      </header>
      <Drawer
        className="drawer__nav--drawer"
        placement="left"
        onClose={onClose}
        open={visible}
      >
        <div className="drawer__nav--header">
          <button onClick={onClose} className="btn drawer__nav--close">
            <Close />
          </button>
        </div>
        <div className="drawer__nav--container">
          <div className="drawer__nav--wrap">
            <ul className="drawer__nav">
              <li>
                <button
                  className={
                    activeTab === "home" && subTab !== "search"
                      ? "nav__link active"
                      : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    onTabChange("home");
                    setSubTab("");
                    // headerAnalytics("Home Page")
                  }}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  className={
                    activeTab === "archives" && subTab !== "search"
                      ? "nav__link active"
                      : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    setSubTab("");
                    onTabChange("archives");
                    // headerAnalytics("Archives Page")
                  }}
                >
                  LionTree Top Themes
                </button>
              </li>
              <li>
                <button
                  className={
                    activeTab === "news" && subTab !== "search"
                      ? "nav__link active"
                      : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    setSubTab("");
                    onTabChange("news");
                    // headerAnalytics("Home Page")
                  }}
                >
                  By Sub-Sector
                </button>
              </li>
              <li>
                <button
                  className={
                    activeTab === "about" && subTab !== "search"
                      ? "nav__link active"
                      : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    setSubTab("");
                    onTabChange("about");
                    // headerAnalytics("About Page")
                  }}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  className={
                    subTab === "search" ? "nav__link active" : "nav__link"
                  }
                  onClick={() => {
                    setVisible(false);
                    setOpen(true);
                   setSubTab("search");
                    // headerAnalytics("Home Page")
                  }}
                >
                  Search
                </button>
              </li>
            </ul>
          </div>
          <div className="drawer__nav--footer">
             {(!localStorage.getItem("t")) && (
             <button className="btn btn-sm btn__white w-100 mb-2"
            onClick={ ()=>
              openMailModal()
            }
             >
                Login
              </button>
               )}
               {!localStorage.getItem('t')&&(
            <a
              onClick={() =>
                handledClicked(
                  "Request Subscription",
                  "https://liontreeuat.wpenginepowered.com/ltweekly?inboundpath=themeweb"
                )
              }
              href="javascript:void(0)"
              // target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-sm btn__white w-100">
                Request Access
              </button>
            </a>
            )}
          </div>
        </div>
      </Drawer>
      {/*<ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="cookies"
        portalClassName="react-modal"
        overlayClassName="modal"
        className="modal-dialog react-modal-dialog-md modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="react-modal-body">
            <div className="cookies-content">
              <div className="cookies-content-text">
                This site uses cookies to track user preferences and to improve user
                experience.
                <strong>
                  {" "}
                  If you do not want us to use cookies, please disable cookies in your
                  web browser.
                </strong>{" "}
                Note that if you <strong>disable cookies,</strong> some website
                functionality will not work for you. For more information about
                cookies and how we handle your personal information, please click on
                our {" "}
                <a
                  onClick={() =>
                    handledClicked(
                      "Privacy Policy",
                      `${process.env.REACT_APP_API_HOSTNAME}/-/media/Privacy/LionTree-PrivacyPolicy.ashx`  
                    )
                  }
                  href="javascript:void(0)"
                >
                  Privacy Policy
                </a>.
              </div>
              <button className="btn btn__purple btn__cookies" onClick={() => handleOk()}>Ok</button>
          </div>
          </div>
        </div>
      </ReactModal>*/}
      <Drawer
        title={"Search"}
        placement={placement}
        onClose={() => onClose()}
        open={open}
        className="sidebarSearch"
      >
        {/* <div className="custom__container"> */}
        <div className="form-group mt-2">
          <input
            placeholder="Search"
            className="form-control search-input"
            value={searchText}
            type="text"
            name="searchText"
            onChange={(e) => {
              setSearchText(e.target.value);
              optimizedUserFn(e);
            }}
          />
        </div>
        {loader ? (
          <Spin
            size="small"
            className="loader__small searchLoaderNew"
            indicator={
              <LoadingOutlined
                style={{ fontSize: 30, color: "#000", textAlign: "center" }}
                spin
              />
            }
          />
        ) : (
          <div className="searchItem">
            {themes?.map((t) => {
              return (
                <div
                  className="itemList"
                  onClick={() =>{
                    if(!localStorage.getItem("t")){
                      openMailModal(getHrefUrl(t.ShortDescription))
                    }
                    else{
                      redirectToLink(getHrefUrl(t.ShortDescription))  
                    }}
                }
                >
                  <div className="itemImg">
                    <img
                      src={
                        t.Photo?.includes("westus2storpublic")
                          ? t.Photo
                          : ThemesImage
                      }
                    />
                  </div>
                  <div className="itemDetail">
                    <div className="itemTitle">
                      {t.ThemeIndex}.{" "}
                      <a
                        // href={getHrefUrl(t.ShortDescription)}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className="itemTitle"
                        style={{ textDecoration: "underline" }}
                      >
                        {getThemeTitle(t.ShortDescription)}
                        {/* {t.Title?.length > 0 && " | "}{t.Title?.replaceAll(" |"," | ")} */}
                      </a>
                    </div>
                    <p className="itemPara">
                      LT Weekly:{" "}
                      {moment(t.ItemName, "MM-DD-YYYY").format("DD MMM, YYYY")}
                    </p>
                    <div className="d-flex flex-wrap">
                      {t.Title !== "" && t.Title !== null && (
                        <p className="tag-name me-2 mb-1">{t.Title}</p>
                      )}
                      {t.SecondaryTitle !== "" &&
                        t.SecondaryTitle !== null &&
                        t.SecondaryTitle?.split(",").map((s) => {
                          return <p className="tag-name me-2 mb-1">{s}</p>;
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
            {news?.map((n) => {
              return (
                <div
                  className="itemList"
                  onClick={() => getExternalUrl(n.ExternalUrl)}
                >
                  <div className="itemImg">
                    <img src={n.PhotoUrl} />
                  </div>
                  <div className="itemDetail">
                    <h1 className="itemTitle">{n.ShortDescription}</h1>
                    <p className="itemPara">
                      {" "}
                      Other News | {n.Titles?.replaceAll(" |", " | ")}
                    </p>
                  </div>
                </div>
              );
            })}
            {showNoDataMessage && !loader && !isSearchTextEmpty && (
              <div className="table__blank">
                <div className="table__blank--text loader__small">
                  No Data Found
                </div>
              </div>
            )}
          </div>
        )}
      </Drawer>
      <ReactModal
                                      isOpen={mailModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeMailModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      onAfterOpen={handleAfterOpenModal}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                        portalClassName="react-modal- email-media-modal"
                                        style={{
                                          overlay: {
                                            zIndex: 1030,
                                            backgroundColor: "rgb(0 0 0 / 75%)"
                                          },
                                          
                                        }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                          <h5 className="header-title">Access Theme</h5>
                                          {/* <button
                                            onClick={closeMailModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                    

                                        <div className="bluebg">
                                          <label className="custom-label">Email Address</label>
                                          <input type="text" className="custom-input"
                                          ref={emailInputRef}
                                          onChange={(e) =>
                                            handleChangeEmail(e.target.value)
                                          } 
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              checkUser(userEmail, themeLink);
                                            }
                                          }}
                                          />
                                        </div>
                                        <div style={{ textAlign: "left", color: "red", paddingLeft:"20px"}}>
                                        {errorMessage && (
                                          <>
                                         {errorMessage}
                                         </>
                                        )}
                                        </div>
                                        <div className="text-end">
                                        <button className="bluebtn MinWidthLogin"
                                        disabled={isButtonDisabled}
                                         onClick={()=>checkUser(userEmail,themeLink)}>
                                         {emailloader ? (
                                     <Spin indicator={antIcon} className="CustomSize" />
                                      ) : (
                                      "Login"
                                       )} </button>
                                        </div>
                                        <div className="text-center">
                                          <p className="gated-popoup"><a href="https://liontreeuat.wpenginepowered.com/ltweekly/?inboundpath=themeweb">Click here</a> if you are not on the list of subscribers.</p>
                                        </div>
                                      </div>
                                    </ReactModal>
                                    {/* <ReactModal
                                      isOpen={successModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeSuccessModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          zIndex: 1030,
                                           backgroundColor: "rgb(0 0 0 / 75%)"
                                        },
                                       
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Check your email</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        {/* </header> */}

                                      

                                        {/* <div className="bluebg"> */}
                                            {/* <p className="mb-0 parastyle">We sent an email to you at {userEmail}. It has a magic link that will sign you in.</p> */}
                                        {/* </div> */}
                                        {/* <div className="text-end"> */}
                                        {/* <button className="closebtn2" */}
                                        {/* onClick={()=>closeSuccessModal()}>Close</button> */}
                                        {/* </div> */}

                                      {/* </div> */}
                                    {/* </ReactModal> */} 
                                    <ReactModal
                                      isOpen={isAcknwoledgmentModalOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeAcknowledgmentModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={false}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          zIndex: 1030,
                                          backgroundColor: "rgba(0, 0, 0, 0.75)"
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                      <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Hi There!</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">A LionTree member will review your request and respond within 24-48 hours.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeAcknowledgmentModal()}>OK</button>
                                        </div>
                                      </div>
                                    </ReactModal>
                                    {/* <ReactModal
                                      isOpen={isLinkModalOpen}
                                      // onAfterOpen={afterOpenModal}
                                      // onRequestClose={closeLinkModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={false}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex:"999"
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Hi There!</h5>
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">The Login link has expired please request again.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeLinkModal()
                                          }
                                        >
                                          OK</button>
                                        </div>
                                      </div>
                                    </ReactModal>*/}
    </>
  );
};
export default Header
